export const generateYPositions = <I>(
  data: Array<I>,
  randomYPos: () => number,
  ellipseRadiusY: number
) =>
  data.reduce((yPositions, _currVal, i) => {
    let tooClose = true
    let yPos: number
    while (tooClose) {
      yPos = randomYPos()
      tooClose = yPositions.some(v => Math.abs(v - yPos) < ellipseRadiusY)
    }
    yPositions[i] = yPos
    return yPositions
  }, [])
