import { select, selectAll } from 'd3-selection'
import { axisBottom } from 'd3-axis'
import { scaleTime } from 'd3-scale'
import { randomInt, randomUniform } from 'd3-random'
import { timeYear } from 'd3-time'
import { min, max } from 'd3-array'
import { timeFormat, timeParse } from 'd3-time-format'
import { easeCubicIn } from 'd3-ease'
import { csv } from 'd3-fetch'
import 'd3-transition'
import { generateYPositions } from './helpers'

const x = window.innerWidth,
  y = window.innerHeight,
  ellipseRadiusY = 40,
  ellipseWidth = 80

const dateFormat = timeFormat('%Y')
const dayMonthYearFormat = timeFormat('%B %e, %Y')
const maxChars = 20

const truncateText = (text: string) => {
  if (text.length > maxChars) {
    return `${text.slice(0, 16)}...`
  }
  return text
}

interface IDatum {
  event: string
  photo_link: string
  photo_taken_date: Date
  event_start_date: Date
  fun_fact_1: string
  fun_fact_2: string
  fun_fact_3: string
  fun_fact_4: string
  location: string
}

const svg = select('div#container')
  .append('svg')
  .attr('preserveAspectRatio', 'xMinYMin meet')
  .attr('viewBox', `0 0 ${x}  ${y}`)
  .classed('svg-content', true)

csv('data.csv', datum => {
  console.log('DATUM', datum)
  const finalObj = Object.fromEntries(
    Object.entries(datum).map(([k, v]) => {
      const newKey = k
        .toLowerCase()
        .split(' ')
        .join('_')
      const newVal = newKey.includes('date') ? timeParse('%m/%d/%Y')(v) : v
      return [newKey, newVal]
    })
  )
  return finalObj
}).then((data: IDatum[]) => {
  console.log('data', data)
  const startDates = data.map(d => d.event_start_date) as Date[]
  const timeMin = min(startDates)
  const timeMax = max(startDates)

  /* 
  
  Make copy of timeMin and timeMax to avoid editing the fields on the objects

  */
  const copyOfTimeMin = new Date(timeMin.getTime())
  const copyOfTimeMax = new Date(timeMax.getTime())

  const timeScale = scaleTime()
    .domain([copyOfTimeMin, copyOfTimeMax])
    .range([0, x])

  /*

  Add some padding to the timeScale so the events with min and max dates don't get cut off

  */
  const yearSpan = timeYear.count(timeMin, timeMax)
  const paddingInDates = yearSpan / 10
  timeScale.domain([
    copyOfTimeMin.setFullYear(copyOfTimeMin.getFullYear() - paddingInDates),
    copyOfTimeMax.setFullYear(copyOfTimeMax.getFullYear() + paddingInDates)
  ])

  const timeAxis = g =>
    g
      .attr('id', 'timeline')
      .attr('transform', `translate(0,${y / 2})`)
      .call(
        axisBottom(timeScale)
          .ticks(timeYear.every(100))
          .tickFormat(d => dateFormat(d))
      )

  svg.append('g').call(timeAxis)

  const aboveTimeLine = randomUniform(
    0 + ellipseRadiusY,
    y / 2 - ellipseRadiusY
  )
  const belowTimeLine = randomUniform(y / 2 + ellipseRadiusY, y - ellipseWidth)
  const yAxis = [aboveTimeLine, belowTimeLine]

  const randomYPos = () => {
    const i = randomInt(0, 2)()
    return yAxis[i]()
  }

  const yPositions = generateYPositions(data, randomYPos, ellipseRadiusY)

  function handleMouseEnter(d: IDatum) {
    console.log('d', d)

    select(this)
      .call(g =>
        g
          .append('text')
          .text(d => truncateText(d.event))
          .attr('dy', '0.5rem')
      )
      .call(g =>
        g
          .append('text')
          .text(d => dateFormat(d.event_start_date))
          .attr('dy', '-1rem')
      )
      .select('ellipse')
      .attr('rx', ellipseWidth)
      .attr('ry', ellipseRadiusY)
      .attr('fill', '#101935')
      .attr('stroke', '#ef7c00')
  }

  function handleMouseLeave(d, i) {
    select(this)
      .call(g => g.select('text').remove())
      .call(g => g.select('text').remove())
      .select('ellipse')
      .attr('rx', 10)
      .attr('ry', 10)
      .attr('fill', '#ef7c00')
  }

  function handleClick(d: IDatum) {
    select('#modal')
      .style('display', 'block')
      .html(_ => {
        return `<div class="modal-content">
      <figure>
        <img
          src=${d.photo_link}
          alt="Such and such"
        />
        <figcaption>Photo taken: ${dayMonthYearFormat(
          d.photo_taken_date
        )}</figcaption>
      </figure>
      <div>
        <h3>${d.event}</h3>
        <h1>${dayMonthYearFormat(d.event_start_date)}</h1>
        <h4>${d.location}</h4>
        <label>Fun facts:</label>
        <ul>
          ${d.fun_fact_1 ? `<li> ${d.fun_fact_1}</li>` : ''}
          ${d.fun_fact_2 ? `<li> ${d.fun_fact_2}</li>` : ''}
          ${d.fun_fact_3 ? `<li> ${d.fun_fact_3}</li>` : ''}
          ${d.fun_fact_4 ? `<li> ${d.fun_fact_4}</li>` : ''}
        </ul>
      </div>
      <span class="close">&times;</span>
    </div>`
      })
    registerCloseButton()
  }

  svg
    .selectAll('.eventCircle')
    .data(data)
    .join('g')
    .attr('class', 'eventCircle')
    .call(g =>
      g
        .append('ellipse')
        .attr('rx', 10)
        .attr('ry', 10)
        .attr('fill', '#ef7c00')
    )
    .on('mouseenter', handleMouseEnter)
    .on('mouseleave', handleMouseLeave)
    .on('click', handleClick)
    .attr('transform', d => {
      return `translate(${timeScale(d.event_start_date)},0)`
    })
    .transition()
    .ease(easeCubicIn)
    .delay((d, i) => i * 50)
    .attr(
      'transform',
      (d, idx) =>
        `translate(${timeScale(d.event_start_date)},${yPositions[idx]})`
    )
})

/*

MODAL

*/

function registerCloseButton() {
  var modal = document.getElementById('modal')
  // Get the <span> element that closes the modal
  var span = document.getElementsByClassName('close')[0]

  // When the user clicks on <span> (x), close the modal
  span.onclick = function() {
    modal.style.display = 'none'
  }
}

// When the user clicks anywhere outside of the modal, close it
window.onclick = function(event) {
  if (event.target == modal) {
    modal.style.display = 'none'
  }
}
